.trainingCol {
    width: 17%;
    display: inline-block;
    border-right: solid 2px #e9ebed;
    margin-right: 2%;
    float: left;
    flex: 1;
}

.trainingCol:last-of-type {
    border-right: none;
}

.trainingRow {
    width: 100%;
    overflow: auto;
    display: flex;
}

.fullWidth div div {
    width: 20%;
}
