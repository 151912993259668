.enterRaceContainer {
  height: 325px;
}

.modelsSelect {
  width: 50%;
}

.bottomButtons {
  float: right;
}
