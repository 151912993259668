.avataaarContainer {
  border-radius: 100%;
  overflow: hidden;
  position: relative;
}

.avataaarComponentContainer {
  position: relative;
}

.avataaarOnClickHover {
  cursor: pointer;
}

.countryFlag {
  position: absolute;
  bottom: -4px;
}

.countryFlag-leaderboard {
  right: -2px;
}

.countryFlag-profile {
  right: 8px;
}

.countryFlag-avatarConfigDialog {
  right: 12px;
}

.avatarFlag-leaderboard {
  font-size: 0.7em;
}
