.communityRaceCards {
  max-width: 1200px;
  max-width: 100%;
  display: grid;
  gap: 2rem;
}

.communityRaceCard {
  background-color: dodgerblue;
  color: white !important;
  padding: 1rem;
  height: 4rem;
}

.communityRaceCardBottom {
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.communityRaceCardTop{
  background-color: #0073BB;
  padding: 32px;
  text-align: center;
  color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.communityRaceCardTimeBanner{
  position: absolute;
  padding: 4px 10px;
  top: 8px;
  left: 8px;
  color: white;
  background: rgba(0,0,0,0.15);
  border-radius: 16px;
  font-weight: bold;
}

@media (min-width: 300px) {
  .communityRaceCards { grid-template-columns: repeat(1, 1fr); }
}

@media (min-width: 1200px) {
  .communityRaceCards { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 1600px) {
  .communityRaceCards { grid-template-columns: repeat(3, 1fr); }
}