.student-container-height {
    height: 100%;
}

.img-div-resp {
    width: 100%;
    height: 100%;
}

.img-size-resp {
    max-height: auto;
    max-width: 100%;
}

.practiceMLImage {
    width: 100%;
    height: 50%;
}