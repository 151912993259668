.learn-module-chapter-card {
  height: 100%;
}

.top {
  min-height: 140px;
}

.bottom {
  position: absolute;
  bottom: 30px;
}
