.errorContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image {
    height: 250px;
    width: 250px;
}
