.footer {
    align-items: center;
    background-color: #232F3E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-shrink: 0;
    font-family: "Amazon Ember";
    font-size: 14px;
    min-height: 45px;
    width: 100%;
    justify-content: center;
    position: sticky;
    z-index: 1000;
}
.footerWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin: 0px 20px;
    max-width: 1440px;
    width: 100%;
}
.footer ul {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.footer li {
    color: #FBFBFB;
    display: block;
    display: flex;
    flex-grow: 1;
    float: left;
    justify-content: center;
    padding: 0px 8px 0px 8px;
    text-align: center;
    text-decoration: none;
}